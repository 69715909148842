.ftr-menu {
	position: fixed;
	bottom: 0;
	margin: auto;
	padding: auto;
}

.ftr-menu {
	width: 100%;
}

.footer-menu {
	/* height: 80px; */
	background-color: #32383e;
	color: #fff;
	padding: 10px 0;
	margin-top: 0px;
}

.copyright {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	line-height: 1.6;
	color: #a1aab4;
	font-size: 14px;
	margin-right: 0px !important;
	margin-left: 0px !important;
}

.ftr-icon {
	max-width: 0.9%;
	max-height: 0.9%;
}

.ftr-icon-md {
	max-width: 1.5%;
	max-height: 1.5%;
}

.ftr-icon-xs {
	max-width: 4%;
	max-height: 4%;
}

.ftr-link {
	color: #fff;
}
