.App-logo-lg {
	margin-top: 21px;
	margin-bottom: 15px;
	margin-right: 20px;
	margin-left: 35px;
	max-width: 30vmin;
}

.App-logo-md {
	margin-top: 21px;
	margin-bottom: 15px;
	margin-right: 30px;
	margin-left: 30px;
	max-width: 15vmin;
}

.App-logo-sm-0 {
	margin-top: 21px;
	margin-bottom: 15px;
	margin-right: 20px;
	margin-left: 35px;
	max-width: 30vmin;
}

.App-logo-xs {
	margin-top: 21px;
	margin-bottom: 15px;
	margin-right: 0px;
	margin-left: 0px;
	max-width: 60vmin;
}

.profile-img-col-xs {
	display: flex;
	justify-content: center;
}

.contact-num-row {
	justify-content: center;
}

.contact-num {
	padding: 3px;
	font-size: 15px;
	border: 3px solid #1472ba;
	background-color: #1472ba;
	margin-right: 50px !important;
	border-radius: 6px;
}

.contact-num a {
	color: white;
	text-decoration: none;
	font-weight: bold;
	background-color: transparent;
}

.contact-num-style {
	text-align: center;
}

.contact-num-sm {
	padding: 3px;
	font-size: 15px;
	border: 3px solid #1472ba;
	background-color: #1472ba;
	margin-right: 50px !important;
	border-radius: 6px;
}

.contact-num-sm a {
	color: white;
	text-decoration: none;
	font-weight: bold;
	background-color: transparent;
}

.contact-num-style-sm {
	text-align: center;
	margin-right: 0px !important;
	max-width: 75%;
	left: 12.5%;
}

.vp2-logo-lg {
	margin-top: 0px;
	margin-bottom: 15px;
	margin-right: 20px;
	margin-left: 35px;
	max-width: 30vmin;
}

.vp2-logo-sm {
	margin-top: 0px;
	margin-bottom: 15px;
	margin-right: 20px;
	margin-left: 20px;
	max-width: 12vmin;
}

.logo-element {
	padding-bottom: 0px !important;
}

.view {
	font-family: "Lato", arial, sans-serif;
	color: #434343;
	background: #dae3e7;
	font-size: 18px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: 400;
}

.app-view-lander {
	max-width: 100%;
	padding-top: 0px;
	display: flex;
	margin-right: 0px;
	margin-left: 0px;
}

.main-view-lander {
	min-width: 100%;
	margin: 0px;
	padding-right: 0px;
	margin-right: 0px;
	margin-left: 0px;
}

.ss-img {
	filter: brightness(69%);
}

.lines-img {
	filter: brightness(69%);
	transform: translateY(-95%);
}

.value-prop {
	background-position: center center !important;
	background-repeat: no-repeat !important;
	filter: brightness(100%);
}

.icon-row {
	margin-right: 0px;
	margin-left: 0px;
	/*margin-top: 36px; NOTE: inline style now*/
}

.icon-row .col {
	text-align: center;
	padding-right: 0px;
	padding-left: 0px;
}

.header-cta-icons {
}

.header-cta {
	color: white;
	font-family: "Montserrat", sans-serif;
}

.header-cta .col {
	display: flex;
	justify-content: center;
}

.header-cta .col h1 {
	font-size: 40px;
	font-weight: 700;
}

.header-cta-md {
	color: white;
	font-family: "Montserrat", sans-serif;
}

.header-cta-md .col {
	display: flex;
	justify-content: center;
}

.header-cta-md .col h1 {
	font-size: 33px;
	font-weight: 700;
}

.header-cta-sm {
	color: white;
	font-family: "Montserrat", sans-serif;
}

.header-cta-sm .col {
	display: flex;
	justify-content: center;
	text-align: center;
}

.header-cta-sm .col h1 {
	font-size: 27px;
	font-weight: 700;
}

.header-cta-titles {
	font-family: "Lato", sans-serif;
	color: white;
	font-size: 18px;
	font-weight: 900;
}

.header-cta-titles-sm {
	font-family: "Lato", sans-serif;
	color: white;
	font-size: 13px;
	font-weight: 900;
	padding-right: 10px;
	padding-left: 10px;
}

.app-navi-bar {
	margin-top: -60.5vh;
}

.app-navi-bar-sm {
	margin-top: 0vh;
}

.app-navi-bar-xs {
	margin-top: 0vh;
	display: block;
}

.ss-bg-img {
	min-height: 85vh;
	min-width: 140%;
	object-fit: contain;
	background-position: center;
	background-size: cover;
	margin-top: 1.2vh;
}

.ss-bg-img-md {
	min-height: 75vh;
	min-width: 140%;
	object-fit: contain;
	background-position: center;
	background-size: cover;
	margin-top: 1.8vh;
}

.ss-bg-img-sm {
	min-height: 85vh;
	object-fit: contain;
	background-position: center;
	background-size: cover;
	margin-top: 1.8vh;
	transform: translateX(-33%);
}

.lines-bg-img {
	min-width: 140%;
	object-fit: contain;
	background-position: center;
	background-size: cover;
	margin-top: -40vh;
}
@media (min-width: 200px) and (max-width: 350px) {
	.lines-bg-img-sm {
		min-width: 140%;
		object-fit: contain;
		background-position: center;
		background-size: cover;
		margin-top: -5vh;
	}
}

@media (min-width: 350px) and (max-width: 380px) {
	.lines-bg-img-sm {
		min-width: 140%;
		object-fit: contain;
		background-position: center;
		background-size: cover;
		margin-top: -24vh;
	}
}

@media (min-width: 380px) and (max-width: 800px) {
	.lines-bg-img-sm {
		min-width: 140%;
		object-fit: contain;
		background-position: center;
		background-size: cover;
		margin-top: -24vh;
	}
}

.porsche-bg-img {
	margin-left: -94vh;
	min-height: 125vh;
	min-width: 140%;
	object-fit: contain;
	background-position: center;
	background-size: cover;
	margin-top: -6vh;
}

.porsche-bg-img-sm {
	margin-left: -214vh;
	min-height: 275vh;
	min-width: 140%;
	object-fit: contain;
	background-position: center;
	background-size: cover;
	margin-top: -30vh;
}

.porsche-img {
	min-height: 145vh;
	object-fit: contain;
	background-position: center;
	background-size: cover;
	margin-top: -12vh;

	transform: translateX(20%);
}

.header-cta-value-avrs {
	/* margin-top: 15%; NOTE: Need to dynamically resize this */
	margin-bottom: 40px;
	padding-bottom: 0em;
	height: 100%;
}

@media (min-width: 200px) and (max-width: 350px) {
	.header-cta-value-avrs-sm {
		/* margin-top: 15%; NOTE: Need to dynamically resize this */
		margin-bottom: 120px;
		padding-bottom: 0em;
		height: 100%;
	}
}

@media (min-width: 350px) and (max-width: 380px) {
	.header-cta-value-avrs-sm {
		/* margin-top: 15%; NOTE: Need to dynamically resize this */
		margin-bottom: 51px;
		padding-bottom: 0em;
		height: 100%;
	}
}

@media (min-width: 380px) and (max-width: 800px) {
	.header-cta-value-avrs-sm {
		/* margin-top: 15%; NOTE: Need to dynamically resize this */
		margin-bottom: 40px;
		padding-bottom: 0em;
		height: 100%;
	}
}

.header-avrs-row {
	/* height: 106%; FIXME: This is 85% for 1366px and up; */
	/* FIXME: Maybe here to adjust row height accordingly; */
	background-image: url("https://cdn-assets.quickautotags.com/images/content/ca-happy-dmv-renewals.jpg");
	background-position: center;
	background-size: cover;
	min-width: 100%; /* this adjusts width of value prop img for testing */
}

.header-avrs-row-sm {
	/*FIXME: This is 85% for 1366px and up; he ight: 1 06%;  */
	/* FIXME: Maybe here to adjust row height accordingly; */
	background-image: url("https://cdn-assets.quickautotags.com/images/content/ca-happy-dmv-renewals.jpg");
	background-position: center;
	background-size: cover;
	min-width: 100%; /* this adjusts width of value prop img for testing */

	position: relative;
	justify-content: center;
	margin-top: -5%;
}

.header-avrs-col-left {
	background: rgba(255, 255, 255, 0.65);

	width: 50%;
	margin-left: 3em;
	margin-right: 2em;

	margin-top: 63px;
	margin-bottom: 63px;
	position: relative;

	border-radius: 9px;
}

.header-avrs-col-left h2 {
	font-family: "Montserrat", sans-serif !important;
	color: black !important;
	font-weight: 700;
	/* font-size: 28px; NOTE: Pass Dynamically */
	text-align: center;
	margin-left: 20px;
	margin-right: 20px;
	position: absolute;
	/* top: 27%; NOTE: Pass Dynamically */
	transform: translateY(-50%);
}

.header-avrs-col-left section ul {
	font-family: "Lato", sans-serif !important;
	color: black !important;
	font-weight: 600;
	/* font-size: 18px; NOTE: Pass Dynamically */

	color: black !important;
	position: absolute;
	/* top: 63%; NOTE: Pass Dynamically */
	transform: translateY(-50%);
	list-style-type: none;

	margin-block-start: 0.5em;
	margin-block-end: 0.5em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 20px;
}

.header-avrs-col-left section ul li {
	padding: 10px;
	text-align: left;
}

.header-avrs-col-left-md {
	background: rgba(255, 255, 255, 0.65);

	width: 50%;
	margin-left: 2.1em;
	margin-right: 1em;

	margin-top: 36px;
	margin-bottom: 36px;
	position: relative;

	border-radius: 6px;
}

.header-avrs-col-left-md h2 {
	font-family: "Montserrat", sans-serif !important;
	color: black !important;
	font-weight: 700;
	/* font-size: 28px; NOTE: Pass Dynamically */
	text-align: center;
	margin-left: 20px;
	margin-right: 20px;
	position: absolute;
	/* top: 27%; NOTE: Pass Dynamically */
	transform: translateY(-63%);
}

.header-avrs-col-left-md section ul {
	font-family: "Lato", sans-serif !important;
	color: black !important;
	font-weight: 600;
	/* font-size: 18px; NOTE: Pass Dynamically */

	color: black !important;
	position: absolute;
	/* top: 63%; NOTE: Pass Dynamically */
	transform: translateY(-60%);
	list-style-type: none;

	margin-block-start: 0.5em;
	margin-block-end: 0.5em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 20px;
}

.header-avrs-col-left-md section ul li {
	padding: 10px;
	text-align: left;
}

.header-avrs-col-right {
	background-color: white;
	border-radius: 9px !important;

	width: 50%;
	margin-left: 2em;
	margin-right: 3em;

	margin-top: 63px;
	margin-bottom: 63px;

	padding-right: 0px !important;
	padding-left: 0px !important;

	text-align: center;
}

.header-avrs-col-right-md {
	background-color: white;
	border-radius: 6px !important;

	width: 50%;
	margin-left: 1em;
	margin-right: 2.1em;

	margin-top: 36px;
	margin-bottom: 36px;

	padding-right: 0px !important;
	padding-left: 0px !important;

	text-align: center;
}

.avrs-widget-sm {
}

.avrs-container-mobile {
}

.header-avrs-col-mobile {
	background-color: rgb(255, 255, 255, 0.85);
	border-radius: 6px !important;

	width: 50%;

	margin-left: 10em;
	margin-right: 10em;
	margin-top: 36px;
	margin-bottom: 36px;

	padding-right: 0px !important;
	padding-left: 0px !important;

	text-align: center;
}

.header-avrs-col-mobile article section {
	padding-right: 10px !important;
	padding-left: 10px !important;
}

@media (min-width: 200px) and (max-width: 350px) {
	.header-avrs-col-mobile-sm {
		background-color: rgb(255, 255, 255, 0.85) !important;
		border-radius: 6px !important;

		width: 89%;

		margin-left: 0em;
		margin-right: 0em;
		margin-top: 30px;
		margin-bottom: 36px;

		padding-right: 15px !important;
		padding-left: 15px !important;
		padding-bottom: 50px;

		text-align: center;

		position: absolute;
	}

	.header-avrs-col-mobile-sm article section {
		padding-right: 0px !important;
		padding-left: 0px !important;
	}
}

@media (min-width: 350px) and (max-width: 380px) {
	.header-avrs-col-mobile-sm {
		background-color: rgb(255, 255, 255, 0.85) !important;
		border-radius: 6px !important;

		width: 89%;

		margin-left: 0em;
		margin-right: 0em;
		margin-top: 42px;
		margin-bottom: 36px;

		padding-right: 15px !important;
		padding-left: 15px !important;
		padding-bottom: 50px;

		text-align: center;

		position: absolute;
	}

	.header-avrs-col-mobile-sm article section {
		padding-right: 0px !important;
		padding-left: 0px !important;
	}
}

@media (min-width: 380px) and (max-width: 800px) {
	.header-avrs-col-mobile-sm {
		background-color: rgb(255, 255, 255, 0.85) !important;
		border-radius: 6px !important;

		width: 89%;

		margin-left: 0em;
		margin-right: 0em;
		margin-top: 72px;
		margin-bottom: 36px;

		padding-right: 15px !important;
		padding-left: 15px !important;
		padding-bottom: 50px;

		text-align: center;

		position: absolute;
	}

	.header-avrs-col-mobile-sm article section {
		padding-right: 0px !important;
		padding-left: 0px !important;
	}
}

.avrs-col-hdr {
	font-family: "Montserrat", sans-serif;
	font-style: normal;
	font-weight: 700;
	text-decoration: none;
	background-color: #007dc6 !important;
	color: #fff;
	font-size: 36px;
	padding: 24px 24px;
	text-transform: none;
	min-width: 100%;
	border-top-left-radius: 9px !important;
	border-top-right-radius: 9px !important;
}

.avrs-col-hdr-md {
	font-family: "Montserrat", sans-serif;
	font-style: normal;
	font-weight: 700;
	text-decoration: none;
	background-color: #007dc6 !important;
	color: #fff;
	font-size: 27px;
	padding: 18px 18px;
	text-transform: none;
	min-width: 100%;
	border-top-left-radius: 6px !important;
	border-top-right-radius: 6px !important;
}

.avrs-col-right {
	padding: 0px !important;
	margin: 0px !important;
}

.ftr-cta-value-avrs {
	/* margin-top: 15%; NOTE: Need to dynamically resize this */
	margin-bottom: 40px;
	padding-bottom: 0em;
	height: 100%;
}

.ftr-avrs-row {
	/*height: 65%;*/
}

.ftr-hdr {
	padding-bottom: 0px;
}

.ftr-avrs-col-left {
	background: none; /*rgba(255, 255, 255, 0.65);*/

	width: 50%;
	margin-left: 5em;
	margin-right: 2em;

	margin-top: 0px;
	margin-bottom: 0px;

	position: relative;

	border-radius: 9px;
}

.ftr-avrs-col-left h2 {
	font-family: "Montserrat", sans-serif !important;
	color: white !important;
	font-weight: 700;
	/* font-size: 28px; NOTE: Pass Dynamically */
	text-align: center;
	margin-left: 0px;
	margin-right: 20px;
	position: absolute;
	/* top: 27%; NOTE: Pass Dynamically */
	transform: translateY(-50%);
}

.ftr-avrs-col-left section ul {
	font-family: "Lato", sans-serif !important;
	color: white !important;
	font-weight: 600;
	/* font-size: 18px; NOTE: Pass Dynamically */

	position: absolute;
	/* top: 63%; NOTE: Pass Dynamically */
	transform: translateY(-50%);
	list-style-type: none;

	margin-block-start: 0.3em;
	margin-block-end: 0.3em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 3px;
}

.ftr-avrs-col-left section ul li {
	padding: 10px;
	text-align: left;
}

.ftr-avrs-col-left section ul li a {
	color: white;
	text-decoration: none !important;
}

.ftr-avrs-col-left-md {
	background: none; /*rgba(255, 255, 255, 0.65);*/

	width: 50%;
	margin-left: 2em;
	margin-right: 1em;

	margin-top: 0px;
	margin-bottom: 0px;

	position: relative;

	border-radius: 9px;
}

.ftr-avrs-col-left-md h2 {
	font-family: "Montserrat", sans-serif !important;
	color: white !important;
	font-weight: 700;
	/* font-size: 28px; NOTE: Pass Dynamically */
	text-align: center;
	margin-left: 0px;
	margin-right: 20px;
	position: absolute;
	/* top: 27%; NOTE: Pass Dynamically */
	transform: translateY(-50%);
}

.ftr-avrs-col-left-md section ul {
	font-family: "Lato", sans-serif !important;
	color: white !important;
	font-weight: 600;
	/* font-size: 18px; NOTE: Pass Dynamically */

	position: absolute;
	/* top: 63%; NOTE: Pass Dynamically */
	transform: translateY(-50%);
	list-style-type: none;

	margin-block-start: 0.3em;
	margin-block-end: 0.3em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 3px;
}

.ftr-avrs-col-left-md section ul li {
	padding: 10px;
	text-align: left;
}

.ftr-avrs-col-left-md section ul li a {
	color: white;
	text-decoration: none !important;
}

.ftr-avrs-col-right {
	background-color: white;
	border-radius: 9px;

	width: 50%;
	margin-left: 2em;
	margin-right: 5em;

	margin-top: 0px;
	margin-bottom: 0px;
}

.ftr-avrs-col-right section .Renewals {
	transform: translateY(12%);
}

.ftr-avrs-col-right-md {
	background-color: white;
	border-radius: 9px;

	width: 50%;
	margin-left: 1em;
	margin-right: 2em;

	margin-top: 0px;
	margin-bottom: 0px;
}

.ftr-avrs-col-right-md section .Renewals {
	transform: translateY(12%);
}

.ftr-avrs-col-right-sm {
	background-color: white;
	border-radius: 9px;

	width: 50%;
	margin-left: 1.5em;
	margin-right: 1.5em;

	margin-top: 0px;
	margin-bottom: 0px;
}

.ftr-avrs-col-right-sm section .Renewals {
	transform: translateY(12%);
}

.porsche-right {
	margin-bottom: 0px;
}

.phone-icon {
	margin-right: 6px;
	color: white;
}

.btn-row {
	justify-content: center;
	/*margin-top: 60px; NOTE: inline style now */
	margin-right: 0px;
	margin-left: 0px;
}

.btn-column {
}

.btn-container {
}

.modal-btn {
	font-family: "Montserrat", sans-serif;
	font-size: 30px;
	font-weight: 600;
	font-variant-caps: all-small-caps;

	color: white;
	background-color: #f06c27 !important;

	border-style: solid;
	border-width: 2px;
	border-radius: 0.5em;
	border-color: #f06c27 !important;

	box-shadow: 0.5px 0.5px 2px 0.5px #bbb;
	transition: 0.2s;
	-webkit-transition: 0.2s;
	cursor: pointer !important;
	pointer-events: auto;
}

.modal-btn:hover,
.modal-btn:focus,
.modal-btn:active {
	color: #bbb;
	background-color: #f06c27;

	border-width: 0.05em;
	box-shadow: 0px 0px 0px 0px black;
	transition: 0.2s;
	-webkit-transition: 0.2s;
	cursor: pointer !important;
	pointer-events: auto;
	filter: brightness(80%);
}

.modal-btn-md {
	font-family: "Montserrat", sans-serif;
	font-size: 21px;
	font-weight: 600;
	font-variant-caps: all-small-caps;

	color: white;
	background-color: #f06c27 !important;

	border-style: solid;
	border-width: 2px;
	border-radius: 0.5em;
	border-color: #f06c27 !important;

	box-shadow: 0.5px 0.5px 2px 0.5px #bbb;
	transition: 0.2s;
	-webkit-transition: 0.2s;
	cursor: pointer !important;
	pointer-events: auto;
	padding: 0.175rem 0.5rem !important;
}

.modal-btn-md:hover,
.modal-btn-md:focus,
.modal-btn-md:active {
	color: #bbb;
	background-color: #f06c27;

	border-width: 0.05em;
	box-shadow: 0px 0px 0px 0px black;
	transition: 0.2s;
	-webkit-transition: 0.2s;
	cursor: pointer !important;
	pointer-events: auto;
	filter: brightness(80%);
}

a[disabled="disabled"] {
	pointer-events: none; /* this is enough for non-IE browsers */
	color: darkgrey; /* IE */
}
/* IE - disable hover effects */
a[disabled="disabled"]:hover {
	cursor: default;
	color: darkgrey;
	text-decoration: none;
}

.checkbox-icon {
	margin-right: 9px;
	color: #f06c27 !important;
}

.lines-col {
	left: 4%;
}

.lines-col-sm {
	left: 0%;
}

.vp2-col {
	justify-content: center;
}

.vp2-col {
	left: 0%;
}

.avoid-the-dmv-line {
	background: rgba(255, 255, 255, 0.5);
	text-align: center;

	border-radius: 9px;
}

.avoid-the-dmv-line h2 {
	color: #0d2547;
	font-family: "Montserrat", sans-serif;
	font-size: 39px;
	font-weight: 800;
	font-variant-caps: all-small-caps;
	padding: 20px;
}
@media (min-width: 200px) and (max-width: 350px) {
	.avoid-the-dmv-line-sm {
		background: rgba(255, 255, 255, 0.5);
		text-align: center;

		border-radius: 9px;
	}

	.avoid-the-dmv-line-sm h2 {
		color: #0d2547;
		font-family: "Montserrat", sans-serif;
		font-size: 21px;
		font-weight: 800;
		font-variant-caps: all-small-caps;
		padding: 20px;
	}
}

@media (min-width: 350px) and (max-width: 380px) {
	.avoid-the-dmv-line-sm {
		background: rgba(255, 255, 255, 0.5);
		text-align: center;

		border-radius: 9px;
	}

	.avoid-the-dmv-line-sm h2 {
		color: #0d2547;
		font-family: "Montserrat", sans-serif;
		font-size: 21px;
		font-weight: 800;
		font-variant-caps: all-small-caps;
		padding: 20px;
	}
}

@media (min-width: 380px) and (max-width: 800px) {
	.avoid-the-dmv-line-sm {
		background: rgba(255, 255, 255, 0.5);
		text-align: center;

		border-radius: 9px;
	}

	.avoid-the-dmv-line-sm h2 {
		color: #0d2547;
		font-family: "Montserrat", sans-serif;
		font-size: 21px;
		font-weight: 800;
		font-variant-caps: all-small-caps;
		padding: 20px;
	}
}

.vp2-row {
	justify-content: center;
}

.vp2 {
	background: rgba(255, 255, 255, 0.5);
	align-content: center;
	text-align: center;
	margin-top: 20px;
}

.vp2 h2 {
	color: #0d2547;
	font-family: "Montserrat", sans-serif;
	font-size: 40px;
	font-weight: 800;
	font-variant-caps: all-small-caps;
	padding: 20px;

	text-align: center;
}

.vp2 h3 {
	color: #0d2547;
	font-family: "Montserrat", sans-serif;
	font-size: 27px;
	font-weight: 800;
	font-variant-caps: all-small-caps;
	padding: 20px;
	text-align: center;
	margin-top: -20px;
}

.vp2-sm {
	background: rgba(255, 255, 255, 0.5);
	align-content: center;
	text-align: center;
	margin-top: 20px;
}

.vp2-sm h2 {
	color: #0d2547;
	font-family: "Montserrat", sans-serif;
	font-size: 24px;
	font-weight: 800;
	font-variant-caps: all-small-caps;
	padding: 20px;

	text-align: center;
}

.vp2-sm h3 {
	color: #0d2547;
	font-family: "Montserrat", sans-serif;
	font-size: 18px;
	font-weight: 800;
	font-variant-caps: all-small-caps;
	padding: 20px;
	text-align: center;
	margin-top: -20px;
}

.vp2-cta-titles {
	font-family: "Lato", sans-serif;
	color: #0d2547;
	font-size: 27px;
	font-weight: 700;
	margin-bottom: 10vh;
}

.vp2-cta-titles-sm {
	font-family: "Lato", sans-serif;
	color: #0d2547;
	font-size: 15px;
	font-weight: 700;
	margin-bottom: 10vh;

	padding-right: 10px;
	padding-left: 10px;
}

.vp2-row {
	margin-right: 0px;
	margin-left: 0px;
	/*margin-top: 36px; NOTE: inline style now*/
}

.vp2-row .col {
	text-align: center;
	padding-right: 0px;
	padding-left: 0px;
}

hr {
	margin-top: 70px;
	margin-right: 45px;
	margin-left: 45px;
	border: 1px solid black;
}
