.pp-container {
	padding-bottom: 9em !important;
}

.pp-p {
	margin: 20px;
}

.pp-heading {
	padding-top: 20px;
}

section {
	color: #999 !important;
	text-align: center;
}

.tcpp-navi-bar {
	margin-top: 0vh;
}
