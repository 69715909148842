.p-modal {
	font-size: 12px !important;
	margin-bottom: 0.4rem;
}

.modal-body {
	position: relative;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 0.5rem;
}

.main-modal {
}

.main-form-modal {
}

.form-renewals {
	text-align: left;
}

.zoom-fields {
	font-size: 18px !important;
}

.loading-fields {
	display: none !important;
}

.mcase-fields {
	width: 100%;
	font-size: 18px !important;
}

.card-object {
	height: 100% !important;
	text-align: left;
}

.receipt-print-modal {
	max-width: 225px !important;
	text-align: left;
}

.receipt-print {
	max-width: 250px !important;
	text-align: left;
}

.loading-card-fields {
	margin-top: 20px;
	min-width: 100%;
	font-size: 18px !important;
}

.loaded-card-fields {
	margin-top: 20px;
	min-width: 100%;
	font-size: 18px !important;
}

.price-card-description {
	animation: loader 2s infinite ease-in-out;
	-webkit-animation: loader 2s infinite ease-in-out;
}

@keyframes loader {
	0% {
		color: rgb(52, 58, 54, 0.1);
	}
	50% {
		color: rgb(52, 58, 54, 0.9);
	}
	100% {
		color: rgb(52, 58, 54, 0.1);
	}
}

.display-price-num {
	color: #778492;
	font-family: "Montserrat", sans-serif;
	font-weight: 800;
	color: #f06c27;
	font-size: 42px;
}

.renew-btn {
	cursor: pointer;
}

.renew-btn-views {
	cursor: pointer;
	background-color: #f06c27 !important;
	border-color: #f06c27 !important;
	border-radius: 21px;
}

.renew-btn-views-3 {
	/*still needed*/
	cursor: pointer;
	background-color: #f06c27 !important;
	border-color: #f06c27 !important;
	border-radius: 21px;

	margin-top: 60px;
}

.billing-row-1 {
	margin-right: 0px !important;
	margin-left: 0px !important;
}

.billing-row-2 {
	margin-right: 0px !important;
	margin-left: 0px !important;
}

.billing-group {
	margin-bottom: 0.4rem;
	text-align: left;
}

.billing-address-2 {
	max-width: 35%;
	padding-right: 0px;
	padding-left: 0px;
}

.billing-city {
	max-width: 65%;
	padding-right: 0px;
}

.billing-state {
	max-width: 35%;
	padding-right: 0px;
	padding-left: 0px;
}

.billing-zip {
	max-width: 65%;
	padding-right: 0px;
}

.geosuggest {
	font-size: 1rem;
	position: relative;
	width: 100%;
	margin: 0px;
	text-align: left;
}

.geosuggest__input {
	width: 100%;
	border: none;
	box-shadow: 0 0 1px #3d464d;
	-webkit-transition: border 0.2s, box-shadow 0.2s;
	transition: border 0.2s, box-shadow 0.2s;

	height: 33px;
	padding: 10px;
	font-size: 14px;
	/* TODO: Possioble throw away; */
	line-height: 1.333333333;
	border-radius: 4px;
	/* TODO: Possioble throw awaysssss; */
	display: block;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #8a6d3b;
	-webkit-box-shadow: inset 0 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s,
		box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.geosuggest__input:focus {
	color: #495057;
	background-color: #fff;
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.geosuggest__suggests {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	max-height: 25em;
	padding: 0px !important;
	margin-top: 0px !important;
	background: #f8f9fa;
	border: 1px solid green;
	border-top-width: 1px;
	overflow-x: hidden;
	overflow-y: auto;
	list-style: none;
	z-index: 5;
	-webkit-transition: max-height 0.2s, border 0.2s;
	transition: max-height 0.2s, border 0.2s;
}

.geosuggest__suggests--hidden {
	max-height: 0;
	overflow: hidden;
	border-width: 0;
}

.geosuggest__item {
	font-size: 14px;
	cursor: pointer;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
	background: #f5f5f5;
}

.geosuggest__item--active {
	background: #267dc0;
	color: #fff;
}

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
	background: #ccc;
}

.geosuggest__item__matched-text {
	font-weight: bold;
}

.help {
	font-size: 0.75em;
	margin: 0.3em;
	color: gray;
	margin-top: 20px !important;
}

.default-num-style {
	display: none;
}

.default-help-style {
	display: none;
}

.email-field {
	margin-right: 0px;
	margin-left: 0px;
}

.email-col-1 {
	padding-left: 0px;
}

.email-col-2 {
	padding-right: 0px;
}

.billing-name-field {
	margin-right: 0px;
	margin-left: 0px;
}

.billing-name-col-1 {
	padding-left: 0px;
}

.billing-name-col-2 {
	padding-right: 0px;
}

.vin-row-field {
	margin-right: 0px;
	margin-left: 0px;
}

.plate-row-field {
	margin-right: 0px;
	margin-left: 0px;
}

.zip-row-field {
	margin-right: 0px;
	margin-left: 0px;
}

.vin-col-1 {
	padding-left: 0px;
	text-align: left;
}

.vin-col-2 {
	padding-right: 0px;
}

.plate-col-1 {
	padding-left: 0px;
	text-align: left;
}

.plate-col-2 {
	padding-right: 0px;
}

.zip-col-1 {
	padding-left: 0px;
	text-align: left;
}

.zip-col-2 {
	padding-right: 0px;
}

.hdr-step-title {
	text-align: left;
}

.hdr-step-title-OFF {
	display: none;
}

.ftr-btn-wide {
	margin-left: 20% !important;
}

.text-muted-view {
	margin-left: 0px !important;
	color: #6c757d !important;
}

.muting-text-views {
	margin-bottom: 2em;
	margin-left: 20% !important;
}

.complete-img-col-lg {
	transform: translateY(20%);
	margin-bottom: 50px;
}

.p-view-renewal-OFF {
	display: none;
}

@media (min-width: 200px) and (max-width: 899px) {
	.p-view-renewal {
		font-size: 12px !important;
		margin-bottom: 0.3rem;
		text-align: left;
	}
}

@media (min-width: 900px) and (max-width: 1359px) {
	.Renewals {
		padding-right: 15px;
		padding-left: 15px;
		padding-top: 5px;
	}

	.p-view-renewal {
		font-size: 14px !important;
		margin-bottom: 0.6rem;
		text-align: left;
	}
}
@media (min-width: 1360px) and (max-width: 1500px) {
	.Renewals {
		padding-right: 40px;
		padding-left: 20px;
		padding-top: 5px;
	}

	.p-view-renewal {
		font-size: 16px !important;
		margin-bottom: 0.9rem;
		text-align: left;
	}
}
@media (min-width: 1501px) {
	.Renewals {
		padding-right: 50px;
		padding-left: 30px;
		padding-top: 5px;
	}

	.p-view-renewal {
		font-size: 18px !important;
		margin-bottom: 1.2rem;
		text-align: left;
	}
}

.shipping-labels {
	font-size: 14px !important;
}
