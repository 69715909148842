.App {
  margin: 0px;
  padding: 0px;
}

.container {
  max-width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}

.logo-element {
  padding-bottom: 0px !important;
}

.App-logo-sm {
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 70vmin;
}

.profile-img-col-sm {
  display: flex;
  justify-content: center;
  padding-right: 0px;
  padding-left: 0px;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 0rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
