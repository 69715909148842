.tc-container {
	margin-bottom: 9em;
}

.tc-p {
	margin: 20px;
}

.tc-heading {
	padding-top: 20px;
}

section {
	color: #999 !important;
	text-align: center;
}

.tcpp-navi-bar {
	margin-top: 0vh;
}
